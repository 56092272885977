@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "flag-icons/css/flag-icons.min.css";

@tailwind base;
@font-face {
    font-family: 'MardotoRegular';
    src: url('./../fonts/Mardoto-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'MardotoBlack';
    src: url('./../fonts/Mardoto-Black.woff2') format('woff2');
}
@font-face {
    font-family: 'MardotoBold';
    src: url('./../fonts/Mardoto-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'MardotoMedium';
    src: url('./../fonts/Mardoto-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'MardotoLight';
    src: url('./../fonts/Mardoto-Light.woff2') format('woff2');
}
@font-face {
    font-family: 'BraindYerevan';
    src: url('./../fonts/BraindYerevan.otf') format('opentype');
}
@font-face {
    font-family: 'LuckiestGuyRegular';
    src: url('./../fonts/LuckiestGuy-Regular.ttf') format('truetype');
}
@tailwind components;
@tailwind utilities;

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

/* Slick slider customisations */
.slick-dots {
    bottom: 25px;
}

.slick-dots li button:before {
    content: ' ';
    border: 4px solid #353535;
    border-radius: 10px;
    opacity: 1;
}

.slick-dots li.slick-active button:before {
    border: 4px solid #f7ac63;
    background-color: #353535;
    opacity: 1;
}

.slick-next,
.slick-prev {
    z-index: 1;
    opacity: 1;
}

.slick-next:before,
.slick-prev:before {
    color: #b91c1c;
    font-size: 35px;
}

.slick-next {
    right: 25px;
}

.slick-prev {
    left: 25px;
}

.origin-top-right {
    /*position: relative; /* or absolute/fixed/sticky */
    z-index: 1000;
}

.iframe-wrapper {
    position: relative;
    padding-bottom: 82.88%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
}

.iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}